import { DateTime } from 'luxon'

const ts = useTimestamp()

export const isWorkingHours = computed(() => {
  if (import.meta.env.SSR) return true

  const dt = DateTime.fromMillis(ts.value, { zone: 'America/Los_Angeles' })

  const day = dt.day
  const hour = dt.hour
  const month = dt.month
  const weekday = dt.weekday

  const queryAfid = getSessionEnhanced().query.afid

  const phoneConfigAfid =
    typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
      ? phoneConfig.value.afid
      : undefined

  const afid = queryAfid || phoneConfigAfid

  // if (
  //   afid === '811301' ||
  //   afid === '811326' ||
  //   afid === '811325' ||
  //   afid === '811329' ||
  //   afid === '811276' ||
  //   afid === '811322' ||
  //   afid === '811284' ||
  //   afid === '811328' ||
  //   afid === '811293' ||
  //   afid === '811311' ||
  //   afid === '811342' ||
  //   afid === '811320' ||
  //   afid === '811349'
  // ) {
  //   return hour >= 5 && hour < 17 && weekday < 6
  // }

  // if (afid === '811310' || afid === '811257' || afid === '811348') {
  //   return hour >= 5 && hour < 17 && weekday < 7
  // }

  // if (afid === '811171') {
  //   return (
  //     (hour >= 5 && hour < 17 && weekday < 7) ||
  //     (hour >= 6 && hour < 16 && weekday === 7)
  //   )
  // }

  return (
    (hour >= 6 && hour < 16 && weekday < 6) ||
    (hour >= 6 &&
      hour < 15 &&
      weekday === 6 &&
      getSessionEnhanced().query.utm_medium !== 'aff')
  )
})
